<template>
  <div class="program view-container">
    <MainCard
      :data="programData"
      class="main-card"/>
    <ProgramTab :list="programList" :selected-program="selectedProgram"></ProgramTab>
  </div>
</template>

<script>
import image from '@/assets/image/program/main.png'
import h5Image from '@/assets/image/program/main-h5.png'
import image1 from '@/assets/image/program/item1.png'
import image2 from '@/assets/image/program/item2.png'
import image3 from '@/assets/image/program/item3.png'
import image4 from '@/assets/image/program/item4.png'
import image5 from '@/assets/image/program/item5.png'
import activeImage1 from '@/assets/image/program/active-item1.png'
import activeImage2 from '@/assets/image/program/active-item2.png'
import activeImage3 from '@/assets/image/program/active-item3.png'
import activeImage4 from '@/assets/image/program/active-item4.png'
import activeImage5 from '@/assets/image/program/active-item5.png'
export default {
  data () {
    return {
      programData: {
        title: '公益项目',
        subtitle: 'Program',
        image,
        h5Image
      },
      // 项目列表
      programList: [
        {
          id: 'cbiScholarship',
          image: image1,
          activeImage: activeImage1,
          text: '富港银行奖学金',
          details: {
            title: '富港银行奖学金',
            pList: [
              '富港银行初升高奖学金的设立，一是为了鼓励学生努力学习，考取高中；二是为了解决贫困学生就读高中的后顾之忧。',
              '每年秋季学期初、春季学期初，我们会对合作学校的学生进行走访，宣传富港银行初升高奖学金，鼓励学生在初三年级努力学习，考取高中。每年6-8月份，学生中考结束，收到高中录取通知书后，可递交申请材料，申请本奖学金。我们将奖学金颁发给符合标准的女学生，鼓励其完成学业，奖学金每人2000元，将于高一第一学期与第一学期助学金一起发放。'
            ]
          }
        },
        {
          id: 'forwardScholarship',
          image: image2,
          activeImage: activeImage2,
          text: '把爱传下去助学金',
          details: {
            title: '把爱传下去助学金',
            pList: [
              '对于获得神州数字初升高奖学金的学生，我们将以助学金的方式继续支持其高中阶段的学习，并对学生进行定期走访。',
              '助学金标准为2000元/人/学期，在每学期初由北京微爱公益基金会打至学生个人银行账户。'
            ]
          }
        },
        {
          id: 'summerDiscoveryCamp',
          image: image3,
          activeImage: activeImage3,
          text: '暑假探索营',
          details: {
            title: '暑假探索营',
            pList: [
              '对于「把爱传下去」项目中的学生，我们将在高一学年的暑假组织其前往北京、上海等城市开展研学、探索夏令营，通过有针对性的行程设置，开拓学生眼界，提高其综合素质能力，帮助其建立更加明确、清晰的目标，为其以后的全面发展奠定基础。'
            ]
          }
        },
        {
          id: 'onlineActivity',
          image: image4,
          activeImage: activeImage4,
          text: '看世界线上活动',
          details: {
            title: '看世界线上活动',
            pList: [
              '「看世界」线上活动是基于「把爱传下去同学会」（或在「把爱传下去同学会」的基础上）定期展开的交互活动。我们将建立同学会社群，定期分享全方面的公开课、讲座、电影等优质视频，希望能够循序渐进的对学生的综合素质产生影响，帮助其提升学习成绩的同时，能够尽可能地开拓其思维模式，使学生不仅能够完成品学兼优的目标，更拥有独立的思辨能力，成为一个拥有自我的人。'
            ]
          }
        },
        {
          id: 'collegeStudentLoans',
          image: image5,
          activeImage: activeImage5,
          text: '大学助学贷款',
          details: {
            title: '大学助学贷款',
            pList: [
              '在高中毕业后，「把爱传下去」所资助的学生中成功考取大学的，我们鼓励学生申请国家助学贷款以支付其学费，对于仍旧希望获得生活费助学金支持的学生，我们将与其签订免息助学金贷款协议承诺书。',
              '我们会在每学年开始，将学生所需的生活费发放至学生个人账户。学生还款有两个选择：一种是在毕业后四年内直接还清贷款，另一种是1v1继续资助其他学生，把爱传下去，这也是我们更鼓励的方式。',
              '同时，对于家庭特别困难的学生，我们也支持其基金参与义工活动，以其他方式奉献爱心。'
            ]
          }
        }
      ]
    }
  },
  created () {
    this.selectedProgram = this.$route.query.id || 'cbiScholarship'
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.program {
  padding-top: 50px;
  .main-card {
    margin-bottom: 50px;
  }
}
@media (max-width: @sm-width) {
  .program {
    padding-top: 15px;
    .main-card {
      margin-bottom: 15px;
    }
  }
}
</style>
